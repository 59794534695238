





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class MeterCard extends Vue {
    @Prop()
    meter!: any

    @Prop()
    meterUnits!: any

    @Prop()
    isNewMeter?: boolean

    get title(): string { return this.meter.name }
    get subtitle(): string { return this.meter.model?.name }
    get address(): string { return this.meter.address }
    get numberOfUnits(): string { return this.meterUnits.length || "Geen" }
}
